<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" :persistent="true">
      <v-list>
          <v-list-item>
              <v-img :src="require('@/assets/ainouheader.svg')" aspect-ratio="1" height="55" class="grey lighten-2"></v-img>
          </v-list-item>
          <router-link v-for="(item, index) in items" :key="index" :to="item.path" custom>
              <template v-slot="{ navigate }">
                  <v-list-item @click="updateTitleAndNavigate(item.title, navigate)">
                      <div style="display: flex; align-items: center;">
                          <v-icon class="mx-3">{{ item.icon }}</v-icon>
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </div>
                  </v-list-item>
              </template>
          </router-link>
          <v-list-item @click="showLogoutDialog = true">
            <div style="display: flex; align-items: center;">
              <v-icon class="mx-3">mdi-logout</v-icon>
              <v-list-item-title>ログアウト</v-list-item-title>
             </div>
          </v-list-item>
      </v-list>

    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>
    <v-dialog v-model="showLogoutDialog" max-width="300px">
      <v-card>
        <v-card-title class="headline">ログアウトしますか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="signOut">はい</v-btn>
          <v-btn color="green darken-1" text @click="showLogoutDialog = false">いいえ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { auth, signOut } from '@/firebaseInit';
//import NewFooter from "@/components/NewFooter.vue";

export default {
  name: "AdminIndex",
  components: {
    
  },
  data() {
    return {
      paymentStatus: null,
      createdAt: null,
      customerId: '',
      drawer: true,
      title: "チャットボット管理",
      showLogoutDialog: false,
      items: [
        { title: 'チャットボット管理', path: '/admin/customize_chat', icon: "mdi-comment-processing-outline" },
        { title: '問い合わせ一覧', path: '/admin/customer_index', icon: "mdi-comment-question-outline" },
        { title: '使い方ガイド', path: '/admin/introduction_ainou', icon: "mdi-television-guide" },
        { title: '支払い管理', path: '/admin/payment_settings', icon: "mdi-bank" },
        { title: 'ユーザー設定', path: '/admin/user_settings', icon: "mdi-account" },
      ],
    };
  },
  methods: {
    async signOut() {
      try {
        await signOut(auth);
        this.$store.commit("setUser", null);
        this.$store.commit('setMessage', 'ログアウトしました');
        this.$router.push({
          name: 'SignIn'
        });
        this.showLogoutDialog = false; // ダイアログを閉じる
      } catch (error) {
        console.error(error);
      }
    },
    updateTitleAndNavigate(newTitle, navigate) {
      this.title = newTitle;
      this.drawer = false;
      navigate();
    },
  },
};
</script>