<template>
  <div class="my-5 mx-2">
    <v-btn color="primary" text to="/"><v-icon>mdi-home</v-icon>Home</v-btn>
  </div> 

  <v-container>
    <v-card>
      <v-card-title class="headline mb-4">プライバシーポリシー</v-card-title>
      
      <v-card-text>
        <p>当サイトのプライバシーポリシーについてご説明します。</p>
        <v-divider class="my-4"></v-divider>

        <h2>収集する情報</h2>
        <p>当サイトでは、以下の情報を収集する場合があります。</p>
        <v-list>
          <v-list-item-group>
            <v-list-item><v-list-item-content>メールアドレス</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>氏名</v-list-item-content></v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider class="my-4"></v-divider>

        <h2>情報の利用</h2>
        <p>当サイトでは、収集した情報を以下の目的で利用することがあります。</p>
        <v-list>
          <v-list-item-group>
            <v-list-item><v-list-item-content>お客様へのサービス提供のため</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>お客様からのお問い合わせに対応するため</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>当サイトの改善に役立てるための分析</v-list-item-content></v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider class="my-4"></v-divider>

        <h2>情報の共有</h2>
        <p>当サイトでは、収集した情報を以下の場合を除き、第三者に開示することはありません。</p>
        <v-list>
          <v-list-item-group>
            <v-list-item><v-list-item-content>お客様の同意がある場合</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>法律に基づく場合</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>当サイトの運営に必要な場合</v-list-item-content></v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider class="my-4"></v-divider>

        <h2>Cookie（クッキー）の使用</h2>
        <p>当サイトでは、Cookieを使用して、お客様により良いサービスを提供するために以下の目的で利用することがあります。</p>
        <v-list>
          <v-list-item-group>
            <v-list-item><v-list-item-content>お客様がサイト内での設定を保持するため</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>アクセス状況の分析のため</v-list-item-content></v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加または変更します */
</style>