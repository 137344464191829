import { createRouter, createWebHistory } from 'vue-router'
import SignIn from '../views/authentication/SignIn'
import SignUp from '../views/authentication/SignUp'
import LeaveService from '../views/authentication/LeaveService'
import ResetPassword from '../views/authentication/ResetPassword'
import AdminIndex from '../views/admin/AdminIndex'
import CustomizeChat from '../views/admin/CustomizeChat'
import PaymentSettings from '../views/admin/PaymentSettings'
import UserSettings from '../views/admin/UserSettings'
import CustomerIndex from '../views/admin/CustomerIndex'
import IntroductionAinou from '../views/admin/IntroductionAinou'
import InquiryForm from '../views/inquiry-form/InquiryForm'
import SpecialCommercialLaw from '../views/site-information/SpecialCommercialLaw'
import TermsOfService from '../views/site-information/TermsOfService'
import PrivacyPolicy from '../views/site-information/PrivacyPolicy'
import SiteInquiryForm from '../views/site-information/SiteInquiryForm'
import store from '../plugins/store'


const routes = [
    {
        path: '/signin',
        name: 'SignIn',
        component: SignIn,
        props: true
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp
    },
    {
        path: '/reset_password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/leave_service',
        name: 'LeaveService',
        component: LeaveService
    },
    {
        path: '/admin',
        name: 'AdminIndex',
        component: AdminIndex,
        meta: { requiresAuth: true },
        children: [
            {
              path: 'customize_chat',
              name: 'CustomizeChat',
              component: CustomizeChat,
            },
            {
                path: 'payment_settings',
                name: 'PaymentSettings',
                component: PaymentSettings,
            },
            {
                path: 'user_settings',
                name: 'UserSettings',
                component: UserSettings,
            },
            {
                path: 'introduction_ainou',
                name: 'IntroductionAinou',
                component: IntroductionAinou,
            },
            {
                path: 'customer_index',
                name: 'CustomerIndex',
                component: CustomerIndex,
            },
        ],
    },
    {
        path: '/inquiry_form/:id',
        name: 'InquiryForm',
        component: InquiryForm,
    },
    {
        path: '/commercial_law',
        name: 'SpecialCommercialLaw',
        component: SpecialCommercialLaw,
    },
    {
        path: '/terms_of_service',
        name: 'TermsOfService',
        component: TermsOfService,
    },
    {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/site_inquiry_form',
        name: 'SiteInquiryForm',
        component: SiteInquiryForm,
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const isLoggedIn = store.getters.isLoggedIn
    if (requiresAuth && !isLoggedIn) {
      next('/signin')
    } else {
      next()
    }
})

export default router