import { createApp } from 'vue'
import App from './App.vue'
import store from './plugins/store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import router from './router/index'
import './firebaseInit'
import { paymentStatus, paymentItemType } from './plugins/constants'

const app = createApp(App)

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$paymentStatus = paymentStatus;
app.config.globalProperties.$paymentItemType = paymentItemType;

app.use(vuetify).use(router).use(store).mount('#app')