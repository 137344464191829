import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, setCustomUserClaims, signOut, updateEmail, updatePassword,EmailAuthProvider,reauthenticateWithCredential, deleteUser, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // refをインポート

const firebaseConfig = {
    apiKey: "AIzaSyAUO7tg56o_k-LOOhUHDt-dTT2XpRww4AQ",
    authDomain: "simple-auto-inquiry.firebaseapp.com",
    projectId: "simple-auto-inquiry",
    storageBucket: "simple-auto-inquiry.appspot.com",
    messagingSenderId: "743116673358",
    appId: "1:743116673358:web:5b46d5e2b2d73ba4008e1f",
    measurementId: "G-BQ3L6C0B2X"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app); 
const storage = getStorage(app);

// 新たにexportするref
export { auth, deleteUser, createUserWithEmailAndPassword, signInWithEmailAndPassword, setCustomUserClaims, signOut,updateEmail, updatePassword,EmailAuthProvider,reauthenticateWithCredential, firestore,firebaseConfig, storage, ref, uploadBytesResumable, getDownloadURL, GoogleAuthProvider, signInWithPopup  }; 
