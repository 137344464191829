import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
  state: {
    user: null,
    message: null
  },
  mutations: {
    setUser(state, user) {
        state.user = user; // ユーザー情報を更新
    },
    setMessage(state, message) {
      state.message = message;
    },
    clearMessage(state) {
      state.message = '';
    },
  },
  actions: {
    // ミューテーションを呼び出すアクションを定義
  },
  getters: {
    currentUser: state => {
        return state.user
      },
    isLoggedIn: state => {
        return state.user !== null;
    }
  },
  plugins: [
    createPersistedState({
      paths: ['user']
    })
  ]  
});

export default store;