<template>
  <div class="guide-download">
    <p>AINOUをご使用前にこちらのガイドをご利用ください</p>
    <v-btn color="cyan lighten-2" @click="downloadGuide">ガイドをダウンロード</v-btn>
  </div>
</template>

<script>
export default {
  name: 'CommonGuide',
  methods: {
    downloadGuide() {
      window.open('https://firebasestorage.googleapis.com/v0/b/simple-auto-inquiry.appspot.com/o/DownloadContents%2FAINOU%E7%B0%A1%E5%8D%98%E6%93%8D%E4%BD%9C%E3%82%AC%E3%82%A4%E3%83%89ver1.0.pdf?alt=media&token=31962595-593c-4888-ad3b-e6ecbbd3e663', '_blank');
    }
  }
}
</script>

<style scoped>
.guide-download {
  text-align: center;
  margin: 20px 0;
}

.guide-download p {
  margin-bottom: 15px;
}
</style>
