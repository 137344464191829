<template>
    <v-tabs
      v-model="tab"
      color="deep-purple-accent-4"
      align-tabs="center"
    >
      <v-tab :value="1">チャットボットデザイン</v-tab>
      <v-tab :value="2">AI読み取り</v-tab>
      <v-tab :value="3">チャットボット作成</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item :value="1">
        <v-container fluid>
          <v-row class="my-1 mx-5">
            <v-col cols="12" md="5">
              <h2 class="my-3">デザインプレビュー</h2>
              <PreviewForm 
                :businessName="businessName"
                :logoFile="logoFile"
                :downloadLogoUrl="downloadLogoUrl"
                :color="color"
                :fontColor="fontColor"
                :note="note"
                :questions="questions"
              />
            </v-col>
            <v-col cols="12" md="7">
              <h2 class="my-3">デザイン</h2>

              <v-text-field
                ref="businessNameField"
                v-model="businessName"
                label="ページに表示するビジネス名"
                :counter="100"
                :rules="[rules.required]"
                class="my-3"
              ></v-text-field>

              <v-row>
                <v-col cols="12" sm="9">
                  <v-file-input 
                    v-model="logoFile"
                    label="ページロゴ画像(png,svg推奨)"
                    accept="image/*"
                    placeholder="画像ファイルを選択してください"
                    prepend-icon="mdi-camera"
                    class="my-3"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="3" v-if="downloadLogoUrl" class="my-3 position-relative">
                    <v-btn 
                      icon 
                      small 
                      class="position-absolute close-icon-btn" 
                      @click="removeLogo"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-img
                      :src="downloadLogoUrl"
                      height="55"
                      contain
                      class="mr-1"
                    />
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                      <div class="text-left mb-1 font-weight-light">テーマカラー</div>
                      <v-color-picker
                          v-model="color"
                          class="ma-2"
                          hide-inputs
                          canvas-height="100"
                          width="200"
                      ></v-color-picker>
                  </v-col>

                  <v-col cols="12" md="6">
                      <div class="text-left mb-1 font-weight-light">フォントカラー</div>
                      <v-color-picker
                          v-model="fontColor"
                          class="ma-2"
                          hide-inputs
                          canvas-height="100"
                          width="200"
                      ></v-color-picker>
                  </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h2 class="my-3">初回メッセージ</h2>
                  <v-textarea
                    v-model="note"
                    variant="filled"
                    :counter="200"
                    :rules="noteRules"
                    auto-grow
                    label="チャットボットページに事前表示する情報"
                    rows="3"
                    row-height="30"
                    shaped
                    color=""
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <div v-for="(question, index) in questions" :key="index">
                    <v-row no-gutters>
                      <v-col cols="10">
                        <v-text-field v-model="questions[index]" label="選択肢" />
                      </v-col>
                      <v-col cols="2">
                        <v-btn color="red" small @click="removeTextField(index)">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="showErrorMessage" class="error-message">
                      最大10の選択肢までです。
                  </div>
                  <v-btn color="green" @click="addTextField">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item :value="2">
        <v-container fluid>
          <v-row>
            <v-container>
              <v-row class="my-3" align="center">
                  <v-col cols="10">
                    <h2>AIに読みこませたい知識の入力</h2>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn @click="insertTemplate">
                      テンプレート挿入
                    </v-btn>
                  </v-col>
                </v-row>
              <v-row class="my-3" align="center">
                <v-textarea
                  ref="descriptionField"
                  v-model="description"
                  label="AIに読み込ませるビジネスの詳細"
                  :counter="3000"
                  :rules="[rules.required, rules.maxLength]"
                  class="my-3 mx-5"
                  rows="20"
                ></v-textarea>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item :value="3">
        <v-container fluid>
          <v-row>
          <!--
            <v-col cols="12">
              <v-switch
                v-model="isChatbotAcctive"
                hide-details
                true-value="true"
                false-value="no"
                color="red"
                :label="`公開ステータス：${isChatbotAcctive}`"
              ></v-switch>
            </v-col>
          -->
            <v-col cols="6">
              <v-btn color="primary" v-if="isChatbotGenerated" @click="editChatbot" class="my-3 mx-3">
                保存と公開をする
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="red" v-if="isChatbotGenerated" @click="dialog = true" class="my-3">
                ボットを削除する
              </v-btn>
              <v-btn color="primary" v-else @click="generateChatbot" class="my-3">
                チャットボットを公開する
              </v-btn>
            </v-col>
            <v-dialog v-model="dialog" persistent max-width="290">
              <v-card>
                <v-card-title class="headline">本当に削除しますか？</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    いいえ
                  </v-btn>
                  <v-btn color="green darken-1" text @click="deleteUserDocument">
                    はい
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col cols="12">
              <v-alert
                v-if="message"
                :value="true"
                type="success"
                dismissible
                @input="clearMessage"
              >
                {{ message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <div v-if="generatedLink" class="mx-auto my-5" max-width="800">
              <v-sheet color="#f0f8ff" class="pa-4 mx-5" :rounded="rounded" elevation="3">
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <h3 class="text-h6 mb-2">共有用URL</h3>
                      <div class="d-flex justify-center align-center text-center">
                        <a :href="generatedLink" target="_blank" class="font-weight-medium">{{ generatedLink }}</a>
                        <v-tooltip :text="tooltipText">
                          <template v-slot:activator="{ props }">
                            <v-icon
                              class="mdi mdi-content-copy ml-2"
                              @click="copyToClipboard(generatedLink)"
                              v-bind="props"
                            ></v-icon>
                          </template>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                      <v-divider class="mb-2"></v-divider>
                      <h3 class="text-h6 mb-2">URLからチャットボットウィジェットを作成する(iframe)</h3>
                      <v-btn v-if="generatedCode" @click="copyToClipboard(generatedCode)" color="blue">コードをコピーする</v-btn>
                      <v-text-field
                        v-model="buttonName"
                        hide-details
                        placeholder="入力文字が開閉ボタンとして表示されます"
                        append-icon="mdi-arrow-right-thick"
                        solo
                        class="my-2"
                        @click:append="generateCode(generatedLink, buttonName)"
                      ></v-text-field>
                      <v-textarea
                        v-if="generatedCode"
                        label="生成コード"
                        v-model="generatedCode"
                        readonly
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </div>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
</template>

  <script>
  import { getStorage, ref, uploadBytes,getDownloadURL, deleteObject} from "firebase/storage";
  import { getFirestore, collection, doc, setDoc, getDoc,deleteDoc, updateDoc } from 'firebase/firestore';
  import PreviewForm from "../common/PreviewForm";
  export default {
    name: "CustomizeChat",
    components: {
      PreviewForm
    },
    data() {
      return {
        tab: null,
        tooltipText: "コピーする",
        note:'',
        noteRules: [
          v => v.length <= 200 || '200文字以下で入力してください'
        ],
        paymentStatus: null,
        businessName: '',
        description: '',
        questions: [''],
        logoFile: null,
        logoFileName: null,
        existedLogoFileName: null,
        dialog: false,
        color: '#000000',
        fontColor: '#ffffff',
        downloadLogoUrl: null,
        rules: {
          required: value => !!value || '必須項目です',
          maxLength: value => value.length <= 3000 || '3000文字以内で入力してください'
        },
        generatedLink: null,
        isChatbotGenerated: false,
        isChatbotAcctive: true,
        generatedCode: null,
        buttonName: null,
        showErrorMessage: false,
        items: [
          { text: 'Black text', class: 'black--text' },
          { text: 'Bold text', class: 'font-weight-bold' },
          { text: 'Medium weight text', class: 'font-weight-medium' },
          { text: 'Normal weight text', class: 'font-weight-regular' },
          { text: 'Light weight text', class: 'font-weight-light' },
          { text: 'Thin weight text', class: 'font-weight-thin' },
          { text: 'Italic text', class: 'font-italic' },
        ],
      };
    },
    computed: {
      message() {
        return this.$store.state.message;
      },
    },
    watch: {
      logoFile(newVal) {
        console.log('logoFile changed in Parent:', newVal);
      },
    },
    mounted() {
      this.loadUserDocument(this.$store.state.user.uid);
    },
    methods: {
      addTextField() {
          if (this.questions.length < 7) {
              this.questions.push('');
              this.showErrorMessage = false;  // エラーメッセージを非表示に
          } else {
              this.showErrorMessage = true;  // エラーメッセージを表示
          }
      },
      removeTextField(index) {
        this.questions.splice(index, 1); 
      },
      generateChatbot() {
        const uid = this.$store.state.user.uid
        if (!this.$refs.businessNameField.validate() || 
            !this.$refs.descriptionField.validate()) {
          return;
        }
        this.generatedLink = `${process.env.VUE_APP_URL}inquiry_form/${uid}`;
        this.uploadLogoToFirebaseStorage(uid);
        this.createUserDocument(uid);
        this.uploadTextToFirebaseStorage(uid);
        this.isChatbotGenerated = true;
        this.$store.commit('setMessage', 'チャットボットの作成が成功しました');
        setTimeout(() => {
          this.$store.commit('clearMessage');
        }, 3000); 
      },
    async editChatbot() {
      const uid = this.$store.state.user.uid;
      if (!this.$refs.businessNameField.validate() || !this.$refs.descriptionField.validate()) {
        return;
      }
      const db = getFirestore();
      const userDocRef = doc(db, "Users", uid, "chatComponent", '0');
      
      //shouldUploadLogoにはbooleanが入る
      const shouldUploadLogo = this.logoFile && this.logoFile[0];
      const fileName = shouldUploadLogo ? this.logoFile[0].name : "";
      const displayNote = this.note.replace(/\n/g, '<br>');
      
      const updateData = {
        businessName: this.businessName,
        color: this.color,
        note: displayNote,
        fontColor: this.fontColor,
        description: this.description,
        generatedLink: this.generatedLink,
        isChatbotAcctive: true,
        questions: this.questions
      };
      
      // logoFileがnullでない場合にのみfileNameを更新
      if (shouldUploadLogo) {
        updateData.fileName = fileName;
      }
      
      await updateDoc(userDocRef, updateData);

      if (this.existedLogoFileName && this.logoFile !== null) {
        this.deleteUserImages(uid, this.existedLogoFileName);
      }

      // existedLogoFileNameが存在し、logoFileがnullでない場合にのみ、以前の画像を削除
      if (this.logoFile !== null) {
        this.uploadLogoToFirebaseStorage(uid);
      }

      this.uploadTextToFirebaseStorage(uid);

      this.$store.commit('setMessage', 'チャットボットの再作成が成功しました');
      setTimeout(() => {
        this.$store.commit('clearMessage');
      }, 3000); 
    },
      async uploadTextToFirebaseStorage(uid) {
          const blob = new Blob([this.description], { type: 'text/plain' });
          const storage = getStorage(); 
          const storageRef = ref(storage, `LoadData/${uid}/read.txt`);

          try {
              await uploadBytes(storageRef, blob);
          } catch (error) {
              console.error('Upload failed:', error);
          }
      },
      async uploadLogoToFirebaseStorage(uid) { 
          const file = this.logoFile ? this.logoFile[0] : null;  // ファイルが存在するか確認
          if (file) {
              const storage = getStorage(); 
              const storageRef = ref(storage, `ChatLogo/${uid}/${file.name}`);
              try {
                  await uploadBytes(storageRef, file); 
                  const downloadURL = await getDownloadURL(storageRef);
                  this.logoFileName = file.name; 
                  this.downloadLogoUrl = downloadURL;
              } catch (error) {
                  console.error('Upload failed:', error);
              }
          } else {
              this.logoFileName = null;
              this.downloadLogoUrl = null;
          }
      },
      async createUserDocument(uid){
          const userDocRef = doc(collection(getFirestore(), "Users"), uid);
          const chatCollRef = collection(userDocRef, "chatComponent");
          const chatDocRef = doc(chatCollRef, '0' );

          const fileName = (this.logoFile && this.logoFile[0]) ? this.logoFile[0].name : ""; 
          const displayNote = this.note.replace(/\n/g, '<br>');
          await setDoc(chatDocRef, {
              businessName: this.businessName,
              note: displayNote,
              fileName: fileName,
              color: this.color,
              fontColor: this.fontColor,
              description: this.description,
              generatedLink: this.generatedLink,
              isChatbotAcctive: true,
              questions: this.questions
          }, { merge: true });
      },

      async loadUserDocument(uid) {
        const userDocRef = doc(collection(getFirestore(), "Users"), uid);
        const docSnap = await getDoc(userDocRef);
        this.paymentStatus = docSnap.data().paymentStatus;
        const chatCollRef = collection(userDocRef, "chatComponent");
        const chatDocRef = doc(chatCollRef, '0' );
        
        try {
          const docSnap = await getDoc(chatDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            this.businessName = data.businessName;
            this.color = data.color;
            this.fontColor = data.fontColor;
            this.description = data.description;
            this.generatedLink = data.generatedLink;
            this.isChatbotGenerated = true;
            this.note = data.note.replace(/<br>/g, '\n');
            this.questions = data.questions;

            if (data.fileName) {
              const storage = getStorage(); 
              const storageRef = ref(storage, `ChatLogo/${uid}/${data.fileName}`);
              const downloadURL = await getDownloadURL(storageRef);
              this.logoFileName = data.fileName;
              this.existedLogoFileName = data.fileName;
              this.downloadLogoUrl = downloadURL;
            }

          } else {
            console.log('No such document!');
            this.isChatbotGenerated = false;
          }
        } catch (error) {
          console.log('Error getting document:', error);
        }
      },
      async deleteUserDocument() {
        const uid = this.$store.state.user.uid
        const userDocRef = doc(collection(getFirestore(), "Users"), uid);
        const chatCollRef = collection(userDocRef, "chatComponent");
        const chatDocRef = doc(chatCollRef, '0' );
        try {
          await deleteDoc(chatDocRef);
          await this.deleteUserImages(uid, this.logoFileName);
          await this.resetData();
          this.dialog = false;
          this.$store.commit('setMessage', 'チャットボットを削除しました');
          setTimeout(() => {
            this.$store.commit('clearMessage');
          }, 3000); 
        } catch (error) {
          console.log('Error deleting document:', error);
        }
      },
      async deleteUserImages(uid, fileName) {
        const storage = getStorage();

        const chatLogoPath = `/ChatLogo/${uid}/${fileName}`;
        const readTxtPath = `/LoadData/${uid}/read.txt`;

        const chatLogoRef = ref(storage, chatLogoPath);
        const readTxtRef = ref(storage, readTxtPath);

        deleteObject(chatLogoRef)
          .then(() => {
            console.log('Chat logo file deleted successfully');
          })
          .catch((error) => {
            console.error('Error deleting chat logo file:', error);
          });

        deleteObject(readTxtRef)
          .then(() => {
            console.log('Read.txt file deleted successfully');
          })
          .catch((error) => {
            console.error('Error deleting read.txt file:', error);
          });
      },
      resetData() {
        this.businessName = '';
        this.description = '';
        this.note = '';
        this.logoFile = null;
        this.logoFileName = null;
        this.color = '#000000';
        this.fontColor = '#ffffff';
        this.downloadLogoUrl = null;
        this.generatedLink = null;
        this.isChatbotGenerated = false;
        this.questions = [''];
      },
      removeLogo() {
        this.downloadLogoUrl = null;
      },
      copyToClipboard(value) {
        const textArea = document.createElement('textarea');
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        this.tooltipText = "コピーしました";
      },
      generateCode(generatedLink, buttonName) {
        let scriptStart = '<script'
        let scriptEnd = '</scrip'
        let kakko = 't>'
        this.generatedCode = scriptStart + ' src="https://storage.googleapis.com/chat_ui_generate/ainou-chat-sdk.js">'+ scriptEnd + kakko + '\n<div class="chat-embed" data-chat-url="' + generatedLink + '" data-button-name="'+ buttonName +'"></div>';
      },
    insertTemplate() {
      this.description = `■サービス概要について
        (ここに支払い方法や決済手段に関しての説明を入れます)

■支払い方法について
(ここに支払い方法や決済手段に関しての説明を入れます)

■価格について
(ここに価格の説明を入れます)

■送料について
(ここに支払い方法や決済手段に関しての説明を入れます)

■商品の返品、交換について

■サポート体制について
(ここにサポート体制に関しての説明を入れます)`;
      }
    },
  };
  </script>

<style scoped>
  .container {
    width: 70%;
    margin: auto;
  }

  h2 {
    position: relative;
    padding-left: 15px; /* 線の幅 + 線とテキストの間の少しのスペース */
    display: inline-block;
  }

  h2::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: blue;
    margin-right: 10px;
  }

.close-icon-btn {
  top: 5px;      /* Adjust these values */
  right: 5px;    /* to position the icon */
  width: 24px;   /* Adjust the width */
  height: 24px;  /* and height to change the size of the button */
  padding: 0;    /* Remove padding */
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

</style>