<template>
    <div class="my-5 mx-2">
      <v-btn color="primary" text to="/"><v-icon>mdi-home</v-icon>Home</v-btn>
    </div>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="text-center">新規登録</v-card-title>
          <v-card-text>
            <v-btn @click="signUpWithGoogle">
              <img src="/google.svg" alt="icon" class="mx-1">
                 Google で登録
            </v-btn>
            <v-form ref="form" @submit.prevent="signUp" class="my-5 mx-2">
              <v-text-field
                label="メールアドレス"
                v-model="email"
                type="email"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                label="パスワード"
                v-model="password"
                type="password"
                :rules="passwordRules"
                required
              ></v-text-field>
              <v-text-field
                label="確認パスワード"
                v-model="passwordConfirmation"
                type="password"
                :rules="passwordConfirmationRules"
                required
              ></v-text-field>
              <v-btn class="my-3" type="submit">登録</v-btn>
            </v-form>
            <router-link to="/signin">ログインはこちら</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { auth, createUserWithEmailAndPassword,GoogleAuthProvider, signInWithPopup } from '@/firebaseInit';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
export default {
  name: 'SignUp',
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      passwordConfirmationRules: [
        v => !!v || '確認パスワードは必須です',
        v => v === this.password || 'パスワードが一致しません'
      ],
      valid: true,
      emailRules: [
        v => !!v || 'Eメールは必須です',
        v => /.+@.+/.test(v) || 'Eメールが正しくありません'
      ],
      passwordRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length >= 8) || 'パスワードは8文字以上でなければなりません'
      ]
    }
  },
  methods: {
    async signUp() {
      if (this.$refs.form.validate()) {
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
          const userDocRef = doc(getFirestore(), "Users", userCredential.user.uid);
          await setDoc(userDocRef, { paymentStatus: this.$paymentStatus.initial, customerId: '' });
          this.$store.commit('setMessage', '登録しました');
          this.$router.push('/signin');
        } catch (error) {
          console.error(error);
        }
      }
    },
    async signUpWithGoogle() {
      try {
        console.log("aaa");
        const provider = new GoogleAuthProvider();
        const userCredential = await signInWithPopup(auth, provider);
        const user = userCredential.user;
        const userDocRef = doc(getFirestore(), "Users", user.uid);
        await setDoc(userDocRef, { paymentStatus: this.$paymentStatus.initial, customerId: '' }, { merge: true });
        this.$store.commit('setMessage', '登録しました');
        this.$router.push('/signin');
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>

</style>