<template>
<v-app>
  <template v-if="paymentStatus === $paymentStatus.paid || paymentStatus === $paymentStatus.trial">
    <v-app-bar :color="chatComponent.color" class="align-center justify-center">
      
      <template v-if="downloadLogoUrl">
          <v-img
              :src="downloadLogoUrl"
              height="55"
              contain
              class="align-self-center"
          />
      </template>
      <div v-else class="dummy-spacer" style="flex-grow: 1; width: 0px; height: 55px;"></div> 

      <span class="font-weight-bold" :style="{ color: chatComponent.fontColor }">{{ chatComponent.name }}</span>

      <div class="dummy-spacer" style="flex-grow: 1; width: 0px; height: 55px;"></div> 

    </v-app-bar>

      <v-main class="main-color">
        <v-container>
          <v-row justify="center" class="mb-1 my-1">
            <v-col cols="12">
              <template v-if="chatComponent.questions && chatComponent.questions.length">
                  <v-card
                    shaped
                    class="mx-auto text-align-left my-1 py-1"
                    outlined
                    style="width: 90%; background-color: #f9fafb;"
                  >
                  <v-card-text v-html="chatComponent.note" class="my-1" style="text-align: left;"></v-card-text>
                  <v-row class="py-2 px-5" justify="center" align="center">
                    <v-col v-for="(question, qIndex) in chatComponent.questions" :key="'question-' + qIndex" cols="12" sm="auto" class="my-1 py-0 px-1 text-center">
                      <v-btn style="color: black; margin: 4px; padding: 8px 12px;" @click="sendMessage(question)">
                        {{ question }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </template>

                <template v-if="messages.length > 0">
                  <div v-for="(message, index) in messages" :key="index" class="py-2 px-5">
                    <v-card
                      shaped
                      class="mx-auto"
                      :class="{ 'text-align-right': message.isFromUser, 'text-align-left': !message.isFromUser }"
                      outlined
                      style="width: 90%;"
                      :style="{ 'background-color': message.isFromUser ? '#fff7ed' : '#f9fafb' }"
                    >
                      <v-card-text class="my-1" v-html="linkify(convertCommaToBreak(message.text))" :style="{ 'color': message.isFromUser ? 'black' : 'black', 'text-align': 'left' }">
                      </v-card-text>
                    </v-card>
                  </div>
                </template>
                
            </v-col>
          </v-row>

          <v-row class="mb-3">
              <v-progress-linear v-if="isLoading" indeterminate :color="chatComponent.color"></v-progress-linear>
          </v-row>       
          <v-row>
            <v-col>
              <v-text-field v-model="inputText" label="メッセージを入力してください" />
              <v-btn @click="sendMessage">メッセージを送る</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </template>
    <v-row v-else justify="center" align="center" style="height: 100vh;">
      <v-col class="text-center">
        <h3>表示できません</h3>
      </v-col>
    </v-row>
  </v-app>
  <v-snackbar
    v-model="snackbar"
    :timeout="3000" 
    color="error"
  >
    {{ snackbarMessage }}
  </v-snackbar>
</template>

<script>
import { getFirestore, collection, doc,getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref,getDownloadURL} from "firebase/storage";
import axios from 'axios';

export default {
  data() {
    return {
      inputText: '',
      messages: [],
      chatComponent: {
        name: '',
        color: '',
        fontColor: '',
        isChatbotAcctive: false,
        note: '',
        questions: null
      },
      isLoading: false,
      downloadLogoUrl: null,
      paymentStatus: null,
      snackbar: false,
      snackbarMessage: ''
    };
  },
  metaInfo() {
    console.log('metaInfo called:', this.chatComponent.name);
    return {
      title: this.chatComponent.name
    };
  },
  async mounted() {
    const uid = this.$route.params.id;
    await this.loadUserDocument(uid);
  },
  methods: {
    linkify(text) {
      const urlPattern = /\bhttps:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/gi;
      return text.replace(urlPattern, url => `<a href="${url}" target="_blank">${url}</a> `);
    },
    convertCommaToBreak(text) {
      text = text.replace(/。/g, '。 ');
      return text.replace(/。 /g, '。<br>');
    },
    async sendMessage(question = null) {
      if (question) {
        this.inputText = question;
      }
      if (!this.inputText.trim()) {
        this.snackbarMessage = "メッセージを入力してください";
        this.snackbar = true;
        return; // ここで処理を終了
      }
      this.messages.push({ text: this.inputText, isFromUser: true });
      this.isLoading = true;

      try {
        
        const response = await axios.post(process.env.VUE_APP_API_URL + 'api/test', {
          param1: this.inputText,
          param2: this.$route.params.id
        });
        this.messages.push({ text: response.data.data, isFromUser: false });
        
        // Firebase Firestoreにデータを追加
        const db = getFirestore();
        const inquiryDoc = doc(db, 'Inquiries', this.$route.params.id);

        // ドキュメントが存在するか確認
        const docSnap = await getDoc(inquiryDoc);
        const currentTime = Timestamp.now();
      if (!docSnap.exists()) {
        await setDoc(inquiryDoc, {
          messages: [{ cutomerText: this.inputText, aiText:response.data.data, timestamp: currentTime }]
        });
      }else {
        const data = docSnap.data();
        const messages = data?.messages || [];
        messages.push({ 
          cutomerText: this.inputText, 
          aiText: response.data.data, 
          timestamp: currentTime 
        });
        await updateDoc(inquiryDoc, { messages });
      }

      } catch (error) {
        console.error('Axios request failed:', error);
      } finally {
        this.inputText = '';
        this.isLoading = false;
      }
    },
    
    async loadUserDocument(uid) {
      const userDocRef = doc(collection(getFirestore(), "Users"), uid);
      const docSnapPayment = await getDoc(userDocRef);
      this.paymentStatus = docSnapPayment.data().paymentStatus;
      const chatCollRef = collection(userDocRef, "chatComponent");
      const chatDocRef = doc(chatCollRef, '0' );
      try {
        const docSnap = await getDoc(chatDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          this.chatComponent.name = data.businessName;
          this.chatComponent.color = data.color;
          this.chatComponent.fontColor = data.fontColor;
          this.chatComponent.isChatbotAcctive = data.isChatbotAcctive;
          this.chatComponent.note = data.note;
          this.chatComponent.questions  = data.questions;
         console.log(this.chatComponent.questions)
        if (data.fileName && data.fileName !== "") {
            const storage = getStorage(); 
            const storageRef = ref(storage, `ChatLogo/${uid}/${data.fileName}`);
            const downloadURL = await getDownloadURL(storageRef);
            this.logoFileName = data.fileName;
            this.downloadLogoUrl = downloadURL;
        } else {
            this.downloadLogoUrl = null;
        }
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error getting document:', error);
      }
    },
  },
};
</script>
<style scoped>
.main-color {
    background-color: #FFFFFF !important;
}

.text-align-right {
  margin-left: auto;
}

.text-align-left {
  margin-right: auto;
}

.align-self-center {
  align-self: center;
}

.info-container {
  padding: 20px;
  background-color: #FFFFFF; /* 背景色を薄いグレーに変更 */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: 0 auto; /* 左右中央寄せのために追加 */
}

.info-text {
  font-size: 12px;
  color: #333;
  line-height: 1.5;
  text-align: left;
}
</style>
