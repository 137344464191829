<template>
  <v-app>
    <v-main>
      <div v-if="$route.path === '/'">
        <WelcomePage/>
      </div>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import WelcomePage from './components/WelcomePage.vue'
import { auth } from "./firebaseInit.js";
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    WelcomePage
  },
  data() {
    return {
      isUserLoggedIn: false,
    };
  },
  computed: {
  ...mapGetters(['currentUser'])
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.isUserLoggedIn = true;
        this.$store.commit("setUser", user);
      } else {
        this.isUserLoggedIn = false;
      }
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
