<template>
  <div class="fixed-header">
    <v-app-bar app fixed color="white" elevation="0">
     <v-img
        :src="require('@/assets/ainouheader.svg')"
        height="55"
        contain
        style="max-width: 500px; max-height: 55px;"
      ></v-img>
      <v-spacer></v-spacer>
      <template v-if="!isMobileView">
        <v-btn text @click.prevent="scrollTo('features')">機能</v-btn>
        <v-btn text @click.prevent="scrollTo('about')">活用シーン</v-btn>
        <v-btn text @click.prevent="scrollTo('introfuctionFlow')">導入の流れ</v-btn>
        <v-btn text @click.prevent="scrollTo('download')">デモ</v-btn>
        <v-btn text @click.prevent="scrollTo('pricing')">プラン</v-btn>
        <v-btn text @click.prevent="scrollTo('contact')">よくあるご質問</v-btn>
        <v-btn text @click.prevent="navigateToInquiryForm">問い合わせ</v-btn>
      </template>
      <v-btn to="/signup" color="primary">登録</v-btn>
      <v-btn to="/signin" color="primary">ログイン</v-btn>
      <v-menu v-if="isMobileView" offset-y>
      <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props" color="blue-lighten-5">
        <v-icon color="#0000CD">mdi-menu</v-icon>
      </v-btn>
      </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="scrollTo('features')">
              <v-list-item-title>特徴</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo('about')">
              <v-list-item-title>事例</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo('introfuctionFlow')">
              <v-list-item-title>導入の流れ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo('download')">
              <v-list-item-title>デモ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo('pricing')">
              <v-list-item-title>プラン</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo('contact')">
              <v-list-item-title>よくあるご質問</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateToInquiryForm">
              <v-list-item-title>問い合わせ</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<style scoped>
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.sub-header {
  display: flex;
  justify-content: space-around; /* サブヘッダー内のリンクを均等に配置 */
  background-color: #f2f2f2;
  padding: 10px;
}

.sub-header a {
  text-decoration: none;
  color: #333; /* リンクのテキスト色 */
  cursor: pointer;
}

.sub-header a:hover {
  text-decoration: underline; /* ホバー時のアンダーライン */
}

.v-btn {
  color: #333; /* リンクのテキスト色 */
  font-weight: bold;
  margin-left: 15px;  /* ボタン間のスペース */
  margin-right: 15px;
}

.v-btn:hover {
  color: #555;  /* ホバー時の色を変更 */
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    logoPath: require('@/assets/img/logo.png'),
    isMobileView: false
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobileView);
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    scrollTo(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        const top = element.offsetTop;
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        });
      }
    },
    navigateToInquiryForm() {
        this.$router.push('/site_inquiry_form');
    },
    checkMobileView() {
      this.isMobileView = window.innerWidth < 850;  // 850pxをブレークポイントとしていますが、適宜変更してください
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkMobileView);
    this.checkMobileView();
  },
};
</script>
