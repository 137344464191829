<template>
  <v-container>
    <v-row justify="center">
      <v-col md="8">
        <v-card class="pa-5">
          <v-card-title class="headline">退会手続き</v-card-title>
          <v-card-text>
            <ul class="custom-list">
              <li>サブスクリプションを継続している場合はキャンセルを行ってください。</li>
              <li>退会を行うと同一のアカウントでログインができなくなります。</li>
              <li>作成コンテンツは全て削除されます。</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="leaveService">退会を行う</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { auth, deleteUser } from '@/firebaseInit';

export default {
  methods: {
    async leaveService() {
      const client_id = this.$store.state.user.uid;
      const userDocRef = doc(collection(getFirestore(), "Users"), client_id);
      const chatComponentDocRef = doc(userDocRef, "chatComponent", "0");
      console.log(chatComponentDocRef);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists() && (userDoc.data().paymentStatus === this.$paymentStatus.paid || userDoc.data().paymentStatus === this.$paymentStatus.trial)) {
        alert('サブスクリプションのキャンセルが済んでいません。支払い管理画面のサブスクリプションキャンセルから、支払いの解約を行なってください。');
        return;
      }

      try {
        await updateDoc(userDocRef, {
          paymentStatus: 100
        });
        await this.deleteChatComponent(chatComponentDocRef);
        this.$router.push({ path: '/' });
      } catch (error) {
        console.error('Error updating document:', error);
      }
    },
    async deleteChatComponent(chatComponentDocRef) {
      try {
        await deleteDoc(chatComponentDocRef);
        console.log("Document successfully deleted!");
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    },
    async deactivateAccount() {
      try {
      await deleteUser(auth.currentUser);
       console.log("User account deactivated successfully");
        } catch (error) {
        console.error("Error deactivating the account: ", error);
        }
    }
  }
};
</script>

<style scoped>
ul.custom-list >>> li {
  list-style-position: inside;
}

.custom-list {
  padding-left: 1em;  /* ulの左パディングを調整 */
}

.custom-list li {
  list-style-type: disc;
  padding-left: 0.2em;  /* テキストと点の間のスペースを調整 */
}
</style>
