<template>
  <div class="preview-wrapper">
<div :style="{ backgroundColor: chatComponent.color }" class="custom-header align-center justify-center">
    <div class="header-content">
        <div>
            <v-img v-if="localNewLogoUrl" :src="localNewLogoUrl" height="55" alt="New Logo Image" class="logo-image" />
            <v-img v-else-if="localExistedLogoUrl" :src="localExistedLogoUrl" height="55" alt="Existed Logo Image" class="logo-image" />
        </div>
        <div class="header-text">
            <span class="font-weight-bold" :style="{ color: chatComponent.fontColor }">{{ chatComponent.name }}</span>
        </div>
    </div>
</div>

    <v-container>
        <!--
        <v-row justify="center" v-if="chatComponent.note"> 
            <v-col cols="12" class="my-3 mx-2"> 
                <v-sheet elevation="2" class="info-container">
                    <div v-html="formattedNote" class="info-text"></div>
                </v-sheet>
            </v-col>
            <v-divider></v-divider>
        </v-row>
        -->
        <v-row class="mb-3">
            <v-col cols="12">
                <template v-if="chatComponent.questions && chatComponent.questions.length">
                    <v-card
                    shaped
                    class="mx-auto text-align-left my-3"
                    outlined
                    style="width: 90%; background-color: #f9fafb;"
                    >
                    <v-card-text v-html="formattedNote" class="my-1" style="text-align: left;"></v-card-text>
                    <v-row class="py-2 px-5" justify="center" align="center">
                        <v-col v-for="(question, qIndex) in chatComponent.questions" :key="'question-' + qIndex" cols="12" sm="auto" class="my-1 py-0 px-1 text-center">
                        <v-btn style="margin: 4px; padding: 8px 12px;" @click="sendMessage(question)">
                            {{ question }}
                        </v-btn>
                        </v-col>
                    </v-row>
                    </v-card>
                </template>
                <div class="py-2 px-5">
                    <v-card
                        shaped
                        class="mx-auto py-2"
                        outlined
                        style="width: 90%; background-color: #fff7ed;"
                    >
                        <v-card-text class="my-1">
                            ユーザーテキスト
                        </v-card-text>
                    </v-card>
                </div>
                <div class="py-2 px-5">
                    <v-card
                        shaped
                        class="mx-auto py-2"
                        outlined
                        style="width: 90%; background-color: #f9fafb;"
                    >
                        <v-card-text class="my-1">
                            AIテキスト
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field v-model="inputText" label="メッセージを入力してください" />
                <v-btn @click="sendMessage">メッセージを送る</v-btn>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PreviewForm',
  data() {
    return {
      chatComponent: {
        name: '',
        color: '',
        fontColor: '',
        note: '',
        questions: [''],
      },
        localExistedLogoUrl: '',
        localNewLogoUrl: '',
      paymentStatus: null,
      logoUrl: ''
    };
  },
  props: {
    businessName: String,
    logoFile: Object, 
    downloadLogoUrl: String,
    color: String,
    fontColor: String,
    note: String,
    questions: Array
  },
  methods: {

  },
    watch: {
        businessName(newVal) {
            this.chatComponent.name = newVal;
        },
        color(newVal){
            this.chatComponent.color = newVal;
        },
        fontColor(newVal){
            this.chatComponent.fontColor = newVal;
        },
        note(newVal){
            this.chatComponent.note = newVal;
        },
        downloadLogoUrl(newUrl) {
            this.localExistedLogoUrl = newUrl;
        },
        questions(newVal) {
            this.chatComponent.questions = newVal;
        },
        logoFile(newFiles) {
        const newFile = newFiles[0];  // ここで配列の最初の要素を取得
        if (newFile && newFile instanceof File) {
            this.localNewLogoUrl = URL.createObjectURL(newFile);
        }
}
    },
  mounted() {
  },
    computed: {
        formattedNote() {
            return this.chatComponent.note.replace(/\n/g, '<br>');
        },
    },
    beforeUnmount() {
        if (this.logoUrl) {
            URL.revokeObjectURL(this.logoUrl);
        }
    }
}
</script>


<style scoped>
.preview-wrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 100vw;
  overflow: hidden;
}

.custom-header {
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
  height: 64px;
  position: relative;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo-image {
    width: 50px;  
    height: auto;
    margin-right: 10px;
}


.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.info-container {
  padding: 20px;
  background-color: #FFFFFF; /* 背景色を薄いグレーに変更 */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: 0 auto; /* 左右中央寄せのために追加 */
}



</style>
