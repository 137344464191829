<template>
  <section id="download">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="4" class="hidden-xs-only">
              <v-img :src="imagePath" class="d-block ml-auto mr-auto mt-7" max-width="350px" />
            </v-col>
            <v-col cols="12" sm="8" offset-sm1 class="white--text text-center">
              <h1 lass="display-2 font-weight-bold mb-4 my-10" style="color: #ffffff; font-size: 40px;">AINOU体験デモ</h1>
              <h5 class="font-weight-light white-text margin-xs">
                是非このパワフルなAIチャットボットのデモを試してください。<br/>
                本サイトの右下部の"AIチャット”ウィジェットからもご利用頂けます。
              </h5>
              <v-btn rounded outlined href="https://ainou.space/inquiry_form/jVx6An5UgYgoqFAkDxePr4lIDF33" target="_blank" large color="white" class="mt-2">
                <v-icon class="mr-2">
                  mdi-application-export
                </v-icon>
                Ainouデモを試す
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
export default {
  data: () => ({
    drawer: null,
    imagePath: require('@/assets/img/ill2.svg'),
  }),
  mounted() {
  },
};
</script>

<style scoped>
#download {
  background-image: url("~@/assets/img/bgDownload.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
}

#download .container,
#download .row {
  height: 100%;
}

.white-text {
    color: white;
}

@media (max-width: 600px) {
  .hidden-xs-only {
    display: none;
  }
  .margin-xs{
    margin-bottom: 50px
  }
}

</style>