<template>
  <v-container class="justify-center">
    <v-row class="justify-center">
      <v-col
        v-for="priceCard in priceCards"
        :key="priceCard.title"
        cols="12"
        sm="6"
        md="4"
        class="text-center"
      >
        <v-card class="mx-auto" max-width="344" v-if="(!customerId || customerId === '') || (paymentStatus !== $paymentStatus.paid && paymentStatus !== $paymentStatus.trial)">
          <v-img
            class="white--text align-end"
            height="200px"
            :src="require('@/assets/Standard.png')"
          >
            <v-card-title class="text-center">{{ priceCard.title }}</v-card-title>
          </v-img>

          <v-card-subtitle class="text-center">{{ priceCard.subtitle }}</v-card-subtitle>
          <v-card-subtitle class="text-center">{{ priceCard.subtitle2 }}</v-card-subtitle>
          <v-card-text class="text-left">
            {{ priceCard.description }}<br/>
            {{ priceCard.description2 }}<br/>
            {{ priceCard.description3 }}<br/>
            {{ priceCard.description4 }}
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn rounded outlined color="blue" class="mx-auto" :href="link" target="_blank">
              お支払い
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-btn v-else @click="showCancelDialog = true" class="mx-auto">サブスクリプションをキャンセルする</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-table class="v-data-table mx-auto">
        <thead>
          <tr>
            <th class="text-start">請求書番号</th>
            <th class="text-start">ステータス</th>
            <th class="text-start">合計額</th>
            <th class="text-start">支払日</th>
            <th class="text-start">請求書PDF</th>
          </tr>
        </thead>
      <tbody>
        <tr v-if="customerId === ''">
          <td colspan="5">課金履歴がありません</td>
        </tr>
        <tr v-else v-for="invoice in invoices" :key="invoice.id">
          <td>{{ invoice.number }}</td>
          <td>{{ invoice.status === 'paid' ? '支払済' : invoice.status }}</td>
          <td>{{ invoice.amount_due }}円(税込)</td>
          <td>{{ invoice.created }}</td>
          <td><a :href="invoice.invoice_pdf" target="_blank">PDF</a></td>
        </tr>
      </tbody>
      </v-table>
    </v-row>
      <!-- キャンセル確認ダイアログ -->
    <v-dialog v-model="showCancelDialog" persistent max-width="400">
      <v-card>
        <v-card-text>本当に解約しますか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showCancelDialog = false">
            いいえ
          </v-btn>
          <v-btn color="red darken-1" text @click="confirmCancellation">
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFirestore, collection, doc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';
export default {
  data() {
    return {
      link: '',
      invoices: [],
      subscriptionId:'',
      customerId: '',
      paymentStatus: null,
      headers: [
        { text: '請求書番号', value: 'number' },
        { text: 'ステータス', value: 'status' },
        { text: '合計額', value: 'amount_due' },
        { text: '支払日', value: 'created' },
        { text: '請求書PDF', value: 'invoice_pdf', sortable: false }
      ],
      priceCards: [
        {
          title: '¥10,000/月',
          subtitle: '初回7日間無料',
          subtitle2: '更に初回クーポンコード適用で60%off(初月のみ)',
          description: '・チャットボット作成(URL、ウィジェット作成)',
          description2: '・3000字までの独自知識の読み取り',
          description3: '・独自知識に基いた自動返答',
          description4: '・AIに来た問い合わせの一覧の表示',
          imgSrc: 'https://example.com/image1.jpg',
        },
      ],
      showCancelDialog: false
    }
  },
  async mounted() {
    const client_id = this.$store.state.user.uid
    const userDocRef = doc(collection(getFirestore(), "Users"), client_id);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      this.customerId = data.customerId;
      this.paymentStatus = data.paymentStatus;
    } else {
      console.log("No such document!");
    }
    let environment = process.env.VUE_APP_ENVIRONMENT;
    if (this.paymentStatus === this.$paymentStatus.cancel) {
        this.link = process.env.VUE_APP_STRIPE_NO_TRIAL_URL + "?prefilled_email=" + this.$store.state.user.email + "&client_reference_id=" + client_id;
    } else {
        this.link = process.env.VUE_APP_STRIPE_URL + "?prefilled_email=" + this.$store.state.user.email + "&client_reference_id=" + client_id;
    }

    if (this.customerId !== '') {
       axios.get('https://script.google.com/macros/s/AKfycbyOPS65QcmGdTNEMkZ325iUV_1VxxCl1zyXbD0KPzj30MjFCq-ULJrlIK-u68H60VKKrA/exec', {
          params: {
            customer_id: this.customerId,
            env: environment
          }
        })
        .then(response => {
          this.invoices = response.data.data;
          this.invoices.forEach(invoice => {
            let date = new Date(invoice.created * 1000);
            invoice.created = date.toLocaleDateString() + " " + date.toLocaleTimeString();
            this.subscriptionId = invoice.subscription;
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  methods: {
    confirmCancellation() {
      this.cancelSubscription();
      this.showCancelDialog = false;
    },
    cancelSubscription() {
      let environment = process.env.VUE_APP_ENVIRONMENT;
      axios.get('https://script.google.com/macros/s/AKfycbwJKPCfQ77qdUXv0D_SBPUjcf5JS_JBLOyXBTWJFk4bHfCPhBJ5eQATqhg2CPcAacZ5xQ/exec', {
        params: {
          subscriptionId: this.subscriptionId,
          env: environment
        }
      })
    .then(response => {
      console.log(response.data);
      const uid = this.$store.state.user.uid;
      try {
        // Update the paymentStatus for the user
        const userDocRef = doc(getFirestore(), "Users", uid);
        setDoc(userDocRef, {
          paymentStatus: this.$paymentStatus.cancel,
        }, { merge: true });

        // Update the isChatbotActive for the chat component
        const chatCollRef = collection(userDocRef, "chatComponent");
        const chatDocRef = doc(chatCollRef, '0');
        setDoc(chatDocRef, {
          isChatbotActive: false
        }, { merge: true });
        
      } catch (error) {
        console.error(error);
      }
    })
    },
  }
}
</script>