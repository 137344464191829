<template>
  <v-app>
    <navigation :color="color" :flat="flat" />
    <v-main class="pt-0">
      <home />
      <about />
      <introductionFlow />
      <download/>
      <pricing  />
      <contact />
    </v-main>
          <div class="chat-embed" data-chat-url="https://ainou.space/inquiry_form/jVx6An5UgYgoqFAkDxePr4lIDF33" data-button-name="AIチャット"></div>
    <foote />
  </v-app>
</template>

<style scoped>
@import "https://storage.googleapis.com/chat_ui_generate/ainou-chat-style.css";
.v-main {
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import navigation from "./HomeNavigation";
import foote from "./NewFooter";
import home from "./HomeSection";
import about from "./AboutSection";
import download from "./DownloadSection";
import contact from "./ContactSection";
import pricing from "./PricingSection";
import introductionFlow from "./IntroductionFlow";
import { initializeChatWidget, destroyChatWidget } from '@/plugins/chatScript.js';


export default {
  name: "App",

  components: {
    navigation,
    foote,
    home,
    about,
    download,
    contact,
    pricing,
    introductionFlow
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  mounted() {
     initializeChatWidget();
  },

  beforeUnmount() {
    destroyChatWidget();
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
  }
};
</script>