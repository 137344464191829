export function initializeChatWidget() {
    let css = document.createElement("link");
    css.href = "https://storage.googleapis.com/chat_ui_generate/ainou-chat-style.css";
    css.rel = "stylesheet";
    css.type = "text/css";
    document.head.appendChild(css);

    // HTMLからdata-chat-urlの値を取得
    const chatEmbedElement = document.querySelector(".chat-embed");
    if (chatEmbedElement) {
      const chatUrl = chatEmbedElement.getAttribute("data-chat-url");
      const buttonName = chatEmbedElement.getAttribute("data-button-name");

      // チャットウィジェットのUIをページに追加
      let chatWidget = document.createElement('div');
      chatWidget.id = 'chat-widget';
      chatWidget.innerHTML = `
          <div id="button-container">
            <button id="open-chat-btn">${buttonName}</button>
          </div>
          <div id="chat-content" class="hidden">
              <iframe src="${chatUrl}" width="500" height="500" frameborder="0"></iframe>
          </div>
      `;
      document.body.appendChild(chatWidget);

      let clickedOnce = false;
      const button = document.getElementById('open-chat-btn');

      button.addEventListener('click', function(event) {
        if (!clickedOnce) {
            clickedOnce = true;
            button.click();
            return;
        }
        console.log("Button clicked!");
        var content = document.getElementById('chat-content');
        var computedDisplay = window.getComputedStyle(content).display;
        if (computedDisplay === 'block') {
            content.style.display = 'none';
            button.innerHTML = buttonName;
            button.classList.remove('open');  // この行を追加
        } else {
            content.style.display = 'block';
            button.innerHTML = "×";
            button.classList.add('open');  // この行を追加
        }
        clickedOnce = false;
        event.stopPropagation();
    });
              
    } else {
      console.error("Element with class .chat-embed not found!");
    }
}

export function destroyChatWidget() {
    const chatWidget = document.getElementById('chat-widget');
    if (chatWidget) {
        chatWidget.remove();
    }
}