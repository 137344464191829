<template>
  <section id="hero">
    <v-parallax dark :src="Image1" height="650">
        <v-row align="center" justify="center" class="fill-height">
            <v-col cols="10">
              <v-row align="center" justify="center" class="fill-height">
                  <v-col cols="12" md="7" xl="8">
                      <h1 class="display-2 font-weight-bold mb-4 my-10" style="color: #ffffff; font-size: 40px;">AIチャットボットを簡単導入</h1>
                      <h2 class="font-weight-light" style="color: white;">
                          難しい知識は不要、どなたでも簡単に導入できます
                          <br/>
                      </h2>
                      <div class="mt-3 text-center sales-container" style="color: white;">
                        <div class="my-5 sales-item" style="border-bottom: 2px solid yellow; display: inline-block; padding-bottom: 5px;">AIチャットボット業界最安値</div>
                        <div class="my-5 sales-item" style="border-bottom: 2px solid yellow; display: inline-block; padding-bottom: 5px;">導入に余計な見積は一切なし</div>
                        <div class="my-5 sales-item" style="border-bottom: 2px solid yellow; display: inline-block; padding-bottom: 5px;">シンプルなUIで誰でも使える</div>
                      </div>
                  </v-col>
                  <v-col cols="12" md="5" xl="4" class="text-right">
                    <router-link to="/signup" class="buttonOutlineGradient">
                      <span class="buttonOutlineGradient_item">まずは無料で始める</span>
                    </router-link>
                  </v-col>
              </v-row>

            </v-col>
        </v-row>
    </v-parallax>


    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h1 lass="display-2 font-weight-bold mb-4 my-10 text-center" style="color: #000000; font-size: 40px;" >AINOUの特徴</h1>
        </v-col>
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      Image1: require('@/assets/img/bgHero.jpg'),
      Image2: require('@/assets/img/borderWaves.svg'),
      dialog: false,
      features: [
        {
          img: require("@/assets/img/icon3.png"),
          title: "簡単デザイン",
          text: "誰でもブランドのイメージに沿ったチャットボットページを簡単デザイン。",
        },
        {
          img: require("@/assets/img/icon2.png"),
          title: "独自の情報を学習させて回答",
          text: "あなたビジネスの細かい知識まで学習させて回答をさせることが可能。",
        },
        {
          img: require("@/assets/img/icon1.png"),
          title: "自然な会話能力",
          text: "ChatGPT®︎で使用される言語処理モデルを採用。まるで人と話しているような体験を顧客に提供。",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    }
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style scoped>
button {
  padding: 0;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

/* ボタンのスタイル */
.buttonOutlineGradient {
  text-decoration: none;
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  height: 80px;
  padding: 2px;
  font-family: sans-serif;
  font-size: 16px;
  text-align: center;
  overflow-wrap: anywhere;
  background: linear-gradient(135deg, #6fa24a, #15a1cc);
  border-radius: 32px; /* (buttonの高さ / 2) の値 */
}

.buttonOutlineGradient::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(135deg, #6fa24a, #15a1cc);
  filter: blur(8px);
  border-radius: 32px; /* (buttonの高さ / 2) の値 */
  opacity: 0;
}

.buttonOutlineGradient_item {
  font-size: 20px;
  position: relative;
  display: flex;
  align-items: center;  
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #ffffff;
  border-radius: 32px; /* (buttonの高さ / 2) の値 */
}

@media (any-hover: hover) {
  .buttonOutlineGradient::before {
    transition: opacity 0.2s;
    will-change: filter;
  }

  .buttonOutlineGradient:hover::before {
    opacity: 1;
  }
}

.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
 z-index: 5;
}

.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
section {
  position: relative;
}

.sales-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sales-item {
  margin: 0 10px;
}

@media (max-width: 767px) {
  .sales-container {
    flex-direction: column;
    align-items: center;
  }

  .sales-item {
    margin: 10px 0;
  }
}
</style>
