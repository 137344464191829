import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myTheme',
        themes: {
          myTheme: {
            dark: false,
            colors: {
                background: '#f2f6ff',
                surface: '#f2f6ff',
                primary: '#0000CD',
                secondary: '#FFD700',
                accent: '#8c9eff',
                error: '#b71c1c',
              },       
          },
        },
      },
});

