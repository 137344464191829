<template>
  <div class="my-5 mx-2">
    <v-btn color="primary" text to="/"><v-icon>mdi-home</v-icon>Home</v-btn>
  </div> 

  <v-container>
    <v-card>
      <v-card-title class="headline mb-4">利用規約</v-card-title>
      
      <v-card-text>
        <h2>1. はじめに</h2>
        <p>この利用規約（以下「本規約」といいます。）は、合同会社エニゴ（以下「当社」といいます。）が提供する「AINOU」（以下「本サービス」といいます。）の利用条件を定めるものです。</p>
        
        <v-divider class="my-4"></v-divider>

        <h2>2. 定義</h2>
        <p>本規約において使用される以下の用語は、次に定める意味を有します。</p>
        <v-list>
          <v-list-item-group>
            <v-list-item><v-list-item-content>「本サービス」とは、当社が提供するサービスの総称をいいます。</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>「利用者」とは、本サービスを利用するすべての方をいいます。</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>「登録情報」とは、利用者が本サービスにおいて登録した情報をいいます。</v-list-item-content></v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider class="my-4"></v-divider>

        <h2>3. 利用料金</h2>
        <p>本サービスの利用には、一部のサービスを除き、利用料金はかかりません。</p>

        <v-divider class="my-4"></v-divider>

        <h2>4. 禁止事項</h2>
        <p>利用者は、以下の行為をしてはなりません。</p>
        <v-list>
          <v-list-item-group>
            <v-list-item><v-list-item-content>法令に違反する行為</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>公序良俗に反する行為</v-list-item-content></v-list-item>
            <v-list-item><v-list-item-content>当社または第三者の知的財産権、プライバシー、名誉、その他の権利を侵害する行為</v-list-item-content></v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider class="my-4"></v-divider>

        <h2>5. 免責事項</h2>
        <p>当社は、本サービスの提供につき、常に十分な注意を払うよう努めますが、本サービスが利用者の期待に適合するものであること、利用者による本サービスの利用が利用者に適したものであること、本サービスが利用者によって特定の目的に適合すること、または本サービスに不具合が生じないことを保証するものではありません。</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'TermsOfService',
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加または変更します */
</style>