<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
         <v-col cols="12">
            <v-btn color="primary" text to="/"><v-icon>mdi-home</v-icon>Home</v-btn>
        </v-col> 
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">問い合わせ</h1>
              <h3 class="font-weight-light mt-3">
                AINOUに関する質問、疑問はこちらによろしくお願いします。<br/>
                迅速な回答をご希望の方は<a href="https://ainou.space/inquiry_form/jVx6An5UgYgoqFAkDxePr4lIDF33">
                こちら</a>のAINOUのAIチャットボットにご質問よろしくお願いします。
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="名前"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="メールアドレス"
                    required
                ></v-text-field>

                <v-textarea
                    v-model="textArea"
                    :rules="textAreaRules"
                    label="メッセージ"
                    required
                />

                <v-btn
                    :disabled="!valid"
                    color="primary"
                    :dark="valid"
                    rounded
                    block
                    class="mt-3"
                    @click="submit"
                >
                  送信する
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #e1e3eb;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

</style>

<script>
// import {db} from '@/main'
import { getFirestore, doc, setDoc } from 'firebase/firestore';
export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "名前フィールドは必須です",
      (v) => (v && v.length >= 6) || "6文字未満です",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Eメールドレスは必須です",
      (v) => /.+@.+\..+/.test(v) || "有効なEメールを入力してください",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "テキストの内容は必須です",
      (v) => (v && v.length >= 10) || "10文字未満です",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    async submit() {
      const db = getFirestore();
      if (this.valid) {
        try {
          const docRef = doc(db, 'Contact', this.email); 
          await setDoc(docRef, {
            name: this.name,
            textArea: this.textArea,
            isRead: false
          });
          alert("送信しました!");
        } catch (error) {
          console.error("エラーが発生しました:", error);
          alert("送信に失敗しました。もう一度お試しください。");
        }
      }
    },
  },
  }
;
</script>
