<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" text to="/"><v-icon>mdi-home</v-icon>Home</v-btn>
      </v-col> 

      <v-col cols="12">
        <div class="mt-5">
          <h1 class="text-h4 mb-5">特定商取引法に基づく表記</h1>

          <h2 class="text-h5 my-3">法人名</h2>
          <p>合同会社エニゴ</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">所在地</h2>
          <p>住所： 〒150-0043 東京都渋谷区道玄坂1丁目10番8号 渋谷道玄坂東急ビル2F-C</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">電話番号</h2>
          <p>050-3674-3869</p>
          <p>月〜金曜日（祝日・年末年始・GW・夏季休業除く） 12:00〜19:00</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">メールアドレス	</h2>
          <p>info@enigo.contact</p>
          <v-divider class="my-5"></v-divider>
      
          <h2 class="text-h5 my-3">運営統括責任者</h2>
          <p>小泉駿大</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">追加手数料等の追加料金</h2>
          <p>追加料金はは生じません</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">交換および返品（返金ポリシー）</h2>
          <p>アプリまたはウェブサイトの支払い管理画面内でサブスクリプションキャンセルお手続により中途解約が可能です。</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">引渡時期</h2>
          <p>購入手続きが完了次第、利用可能な状態になります。</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">受け付け可能な決済手段</h2>
          <p>クレジットカード、ApplePay</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">決済期間</h2>
          <p>購入後直ちに購入処理されます。</p>
          <v-divider class="my-5"></v-divider>

          <h2 class="text-h5 my-3">販売価格</h2>
          <p>¥10,000 詳しくは商品の紹介ページをご覧ください。なお、インターネットに接続するための通信料は別途ご負担ください。</p>
          <v-divider class="my-5"></v-divider>

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'CommercialTransactionAct',
};
</script>
