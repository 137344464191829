<template>
  <section id="introfuctionFlow">
    <v-container fluid>
         <v-row align="center" justify="center">
            <v-col cols="12">
                <h1 lass="display-2 font-weight-bold mb-4 my-10" style="color: #000000; font-size: 40px;" >導入の流れ</h1>
                <v-timeline side="end" align="start">
                    <v-timeline-item dot-color="pink" size="small">
                        <div class="d-flex">
                            <strong class="me-4">Step1</strong>
                            <div class="text-left">
                                <strong>AINOUに新規登録</strong>
                                <div class="text-caption ml-0">
                                    面倒な手続きなしで10秒で簡単登録可能です。
                                </div>
                            </div>
                        </div>
                    </v-timeline-item>

                    <v-timeline-item
                    dot-color="teal-lighten-3"
                    size="small"
                    >
                    <div class="d-flex align-items-start">
                        <strong class="me-4">Step2</strong>
                        <div class="text-left">
                        <strong>チャットボットのデザイン作成</strong>
                        <div class="text-caption mb-2">
                            分かりやすい管理画面であっという間にチャットボット画面をレイアウトできます。
                        </div>
                        </div>
                    </div>
                    </v-timeline-item>

                    <v-timeline-item
                    dot-color="pink"
                    size="small"
                    >
                    <div class="d-flex align-items-start">
                        <strong class="me-4">Step3</strong>
                        <div class="text-left">
                        <strong>独自知識の入力</strong>
                        <div class="text-caption mb-2">
                            AIに回答させたい内容をフォームに入力します。まずは箇条書きで書いていくだけでも問題ないです。
                        </div>
                        </div>
                    </div>
                    </v-timeline-item>

                    <v-timeline-item
                    dot-color="teal-lighten-3"
                    size="small"
                    >
                    <div class="d-flex align-items-start">
                        <strong class="me-4">Step4</strong>
                        <div class="text-left">
                        <strong>チャットボットの共有</strong>
                        <div class="text-caption mb-2">
                            顧客へ専用ページのURL共有もしくは、発行されるウィジェットの1コードをwebページにコピペで設置できます。
                        </div>
                        </div>
                    </div>
                    </v-timeline-item>

                    <v-timeline-item
                        dot-color="pink"
                        size="small"
                    >
                        <div class="d-flex align-items-start">
                            <strong class="me-4">Step5</strong>
                            <div class="text-left">
                                <strong>運用開始</strong>
                                <div class="text-caption">
                                    問い合わせ履歴を見て回答できていない質問があれば、回答できるよう知識を再度AIに読みこませ精度改善していきましょう。
                                </div>
                            </div>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </v-col>
         </v-row>
    </v-container>
  </section>
</template>
<script>
  export default {
    data: () => ({
      items: [
        {
          id: 1,
          color: 'info',
          icon: 'mdi-information',
        },
        {
          id: 2,
          color: 'error',
          icon: 'mdi-alert-circle',
        },
      ],
    }),
  }
</script>
