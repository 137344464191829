<template>
    <CommonGuide />
</template>

<script>
import CommonGuide from '../common/CommonGuide'
export default {
  name: "IntroductionAinou",
  components: {
    CommonGuide
  },
}
</script>

<style scoped>

</style>