<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-card v-if="messages.length" class="elevation-1 mx-auto">
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(message, index) in messages" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{index + 1 }}.{{ message.cutomerText }} {{ formatDate(message.timestamp.toDate()) }}</v-list-item-title>
                        <div class="message-content">{{ message.aiText }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-alert v-else type="info">メッセージはありません</v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  name: 'CustomerIndex',
  data() {
    return {
      messages: [],
    };
  },
  computed: {
    uid() {
      return this.$store.state.user.uid;
    }
  },
  methods: {
    async fetchData() {
      const db = getFirestore();
      const inquiryDoc = doc(db, 'Inquiries', this.uid);
      const docSnap = await getDoc(inquiryDoc);

      if (docSnap.exists()) {
        this.messages = docSnap.data().messages || [];
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      return new Intl.DateTimeFormat('ja-JP', options).format(date);
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>
.v-card, .v-list, .v-list-item, .v-list-item-content, .v-list-item-group {
  text-align: left !important;
}

.message-content {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  color: rgba(0,0,0,0.6);
  font-size: 14px;
}
</style>
