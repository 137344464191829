<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row justify="center" class="mb-1">
        <v-col cols="12" sm="10" md="10" lg="10">
          <h1 class="display-2 font-weight-bold mb-4 my-10" style="color: #000000; font-size: 40px;" >よくある質問</h1>
          <v-sheet
            :rounded="rounded"
            color="green-lighten-3"
          >
            <v-expansion-panels variant="accordion">
              <v-expansion-panel v-for="item in faqs" :key="item.title" :title="item.title" :text="item.text">
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped>
#contact {
  background-color: #e1e3eb;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

</style>

<script>
// import {db} from '@/main'

export default {
  data: () => ({
    faqs: [
      {
        title: 'Q.サポート体制はどうなっていますか？',
        text: 'A.無制限のメールサポートを行なっております。管理画面の使い方からAIに精度の高い回答を行わせるコツなど気兼ねなくお伺いください。',
      },
      {
        title: 'Q.自動と手動対応、切り替えて行うことはできますか？',
        text: 'A.現状対応しておりませんが、別途問合せフォームのURLなどをAIに読みこませることで問合せフォームへ誘導するといった運用を行うことが可能です。',
      },
      {
        title: 'Q.AIに読み込ませた情報がChatGPTなどで再利用されないか心配',
        text: 'A.独自の情報がOPEN AIの言語モデルに再利用されることはありません。',
      },
      {
        title: 'Q.質問に関係のない回答や誤答はしませんか？',
        text: 'A.原則お客様がAIに読み込ませた回答のみを行うようチューニングしておりますが、場合により一般常識を踏まえた回答を行います',
      },   
      {
        title: 'Q.スマートフォンやタブレット端末でも使用できますか？',
        text: 'A.管理画面、チャットボットページ共にスマートデバイスからご利用頂けます。',
      },        
    ],
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "O campo nome é obrigatório",
      (v) => (v && v.length >= 6) || "O nome precisa ter mais de 6 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "O campo email é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O E-mail precisa ser válido",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "O campo de texto é obrigatório",
      (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
};
</script>
