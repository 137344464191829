<template>
  <div>
    <div class="my-5 mx-2">
        <router-link to="/">Home<v-icon style="color: black;">mdi-home</v-icon></router-link>
    </div> 
    <v-container>
        <div v-if="error" class="error">{{ error }}</div>
        <div v-if="message" class="message">{{ message }}</div>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card>
                <v-card-title class="text-center">パスワードリセット</v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="resetPassword">
                        <v-text-field v-model="email" label="Email" type="email" required></v-text-field>
                        <v-btn type="submit" color="primary">パスワードリセットメールを送信</v-btn>
                    </v-form>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>
<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
    name: "ResetPassword",
    data() {
        return {
            email: '',
            error: '',
            message: '',
        };
    },
    methods: {
    async resetPassword() {
        const auth = getAuth();
        const emailAddress = this.currentUser.email;

        try {
        await sendPasswordResetEmail(auth, emailAddress);
        this.message = 'パスワードリセットメールを送信しました。メールを確認してください。';
        } catch (error) {
        this.error = 'メール送信に失敗しました: ' + error.message;
        }
    },
    },
}
</script>
