<template>
  <section id="about">
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-row align="center" justify="center">
                    <v-col cols="12" md="7">
                        <h1 class="font-weight-light display-2"></h1>
                        <h1 lass="display-2 font-weight-bold mb-4 my-10" style="color: #000000; font-size: 40px;" >
                            AINOUで業務を自動化<br/>売上アップやコスト削減に貢献
                        </h1>
                        <v-row>
                            <v-col cols="12" class="d-flex align-center">
                                <v-img
                                    :src="Image2"
                                    max-width="60px"
                                    class="mr-4"
                                />
                                <div>
                                    <h3>お客様からのお問合せ対応</h3>
                                    <p class="text-justify">
                                        自動応答によりユーザーによる自己解決が促されるため<br/>
                                        問い合わせ件数を削減することができます。
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" class="d-flex align-center">
                                <div>
                                    <h3>顧客接点を増やす</h3>
                                    <p class="text-justify">
                                        ホームページやランディングページにたった数行のコードをコピーし埋め込むだけ<br/>
                                    </p>
                                </div>
                                <v-img
                                    :src="Image4"
                                    max-width="60px"
                                    class="mr-4"
                                />
                            </v-col>
                            <v-col cols="12" class="d-flex align-center">
                                <v-img
                                    :src="Image3"
                                    max-width="60px"
                                    class="mr-4"
                                />
                                <div>
                                    <h3>社内でのお問合せ対応</h3>
                                    <p class="text-justify">
                                        社内からの問い合わせはまるっとAINOUにお任せ。<br/>
                                        AINOUを利用し手軽に従業員の自己解決を支援できます。
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="5" class="d-none d-md-flex">
                        <v-img
                            :src="Image1"
                            class="d-block ml-auto mr-auto"
                            max-width="400px"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  data() {
    return {
      Image1: require('@/assets/img/ill1.svg'),
      Image2: require('@/assets/img/icon1.svg'),
      Image3: require('@/assets/img/icon2.svg'),
      Image4: require('@/assets/img/icon3.svg'),

    }
  }
}
</script>

<style scoped>
#about {
  background-color: #e1e3eb;
}
</style>
